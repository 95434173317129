.documentHead {
    text-align: center;
}
.documentInput {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}

.documentLabel {
    display: flex;
    align-items: center;
    gap: 10px;
}

#documentLabelParagraph {
    margin: 0
}
